<template>
  <div class="com-user-MyProduct">
    <div class="product-list">
      <CardProduct
        :data="item"
        v-for="(item, index) in list"
        :key="index"
        :class="{
          'first-product': index === 0,
          'product-active': active === index,
        }"
      />
    </div>
    <div class="product-bar d-flex justify-content-center align-items-center">
      <a-icon class="icon" type="left" @click="prevClick" />
      <a
        v-for="(item, index) in list"
        :key="index"
        :class="{ active: active === index }"
        @click="activeClick(index)"
      ></a>
      <a-icon class="icon" type="right" @click="nextClick" />
    </div>
  </div>
</template>

<script>
import { components } from "@/plugins/utils";

export default {
  ...components({
    "user/index": ["CardProduct"],
  }),
  props: {
    list: Array,
  },
  data() {
    return {
      active: 0,
      width: 700,
      timer: null,
      autoTimer: null,
    };
  },
  created() {},
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize);
    this.autoStartTimer();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.autoTimer);
  },
  methods: {
    resize() {
      if (document.body.clientWidth <= 1440) {
        this.width = document.body.clientWidth - 294 - 480;
      } else {
        this.width = document.body.clientWidth - 294 - 615;
      }
      this.$el.querySelector(".product-list").style.width = this.width + "px";
    },
    activeClick(index) {
      this.autoStartTimer();
      clearInterval(this.timer);
      this.active = index;
      // 最后和第一计算方法
      let productWidth = 610;
      if (document.body.clientWidth <= 1440) {
        productWidth = 510;
      }
      if (index === 0 || index === this.list.length - 1) {
        this.moveScroll(index * productWidth);
      } else {
        this.moveScroll(index * productWidth);
      }
    },
    moveScroll(num) {
      const ele = this.$el.querySelector(".product-list");
      let isPrev = ele.scrollLeft > num;
      const step = 10;
      this.timer = setInterval(() => {
        if (isPrev) {
          ele.scrollLeft -= step;
          if (ele.scrollLeft < num || ele.scrollLeft === 0) {
            ele.scrollLeft = num;
            return clearInterval(this.timer);
          }
        } else {
          ele.scrollLeft += step;
          if (
            ele.scrollLeft > num ||
            ele.scrollLeft >= ele.scrollWidth - this.width
          ) {
            return clearInterval(this.timer);
          }
        }
      }, 1);
    },
    prevClick() {
      let { active } = this;
      active -= 1;
      if (active < 0) return;
      this.activeClick(active);
    },
    nextClick() {
      let { active, list } = this;
      active += 1;
      if (active >= list.length) return;
      this.activeClick(active);
    },
    autoStartTimer() {
      clearInterval(this.autoTimer);
      this.autoTimer = setInterval(() => {
        let { active, list } = this;
        active += 1;
        if (active >= list.length) {
          const ele = this.$el.querySelector(".product-list");
          ele.scrollLeft = 0;
          this.active = 0;
          return;
        }
        this.activeClick(active);
      }, 5000);
    },
  },
};
</script>

<style lang="less" scoped>
.com-user-MyProduct {
  padding: 0 20px 0 25px;
  .product-list {
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 4px;
    overflow: hidden;
    transition: 1s;
    display: -webkit-box;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    .com-user-CardProduct {
      margin: 0 15px;
      &.first-product {
        margin-left: 0;
      }
      &.product-active {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
  .product-bar {
    padding: 5px 0;
    i {
      color: @primary-color;
      margin: 0 10px;
      cursor: pointer;
    }
    a {
      width: 5px;
      height: 5px;
      background: @primary-color;
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
      &.active {
        width: 10px;
        height: 10px;
        background: #999;
      }
    }
  }
}
</style>